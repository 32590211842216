import React from "react";
import ALink from "../../components/a-link/a-link";

import ServiceIconWebsite from "../../images/offer/service_icon_website.svg";
import ServiceIconECommerce from "../../images/offer/service_icon_e-commerce.svg";
import ServiceIconDesktop from "../../images/offer/service_icon_desktop.svg";
import ServiceIconMobile from "../../images/offer/service_icon_mobile.svg";
import ServiceIconDesign from "../../images/offer/service_icon_design.svg";
import ServiceIconSoftware from "../../images/offer/service_icon_software.svg";
import ServiceIconConsulting from "../../images/offer/service_icon_consulting.svg";
import ServiceIconInfrastructure from "../../images/offer/service_icon_infrastructure.svg";

// import ServiceIconWebsiteInline from "../../images/offer/service_icon_website.inline.svg";
// import ServiceIconECommerceInline from "../../images/offer/service_icon_e-commerce.inline.svg";
// import ServiceIconDesktopInline from "../../images/offer/service_icon_desktop.inline.svg";
// import ServiceIconSoftwareInline from "../../images/offer/service_icon_software.inline.svg";
// import ServiceIconMobileInline from "../../images/offer/service_icon_mobile.inline.svg";
// import ServiceIconDesignInline from "../../images/offer/service_icon_design.inline.svg";
// import ServiceIconConsultingInline from "../../images/offer/service_icon_consulting.inline.svg";
// import ServiceIconInfrastructureInline from "../../images/offer/service_icon_infrastructure.inline.svg";

// import Img from "gatsby-image";
// import BackgroundImage from "gatsby-background-image";

import { PageContext, PostPageContext } from "../../../types";

import "./offer-shortcut.scss";

interface Props {
  children?: any;
  className?: string;
  context: PageContext | PostPageContext;
}

class OfferShortcut extends React.Component {
  language = "pl";

  constructor(public params: Props) {
    super(params);
    this.language = params.context.language;
  }

  serviceIcon = [
    {
      name: "Strony internetowe",
      to: "/strony-internetowe",
      img: ServiceIconWebsite,
      // src: <ServiceIconWebsiteInline title="Websites icon" />,
      alt: "Ikona okna przeglądarki ze stroną internetową",
      description: `<strong>Kompleksowe wykonanie stron www</strong>. 
      Projekty, kodowanie, administracja, pozycjonowanie i&nbsp;kampanie reklamowe Ad Words.`,
    },
    {
      name: "E-commerce",
      to: "/sklepy-internetowe",
      img: ServiceIconECommerce,
      // src: <ServiceIconECommerceInline title="ECommerce icon" />,
      alt: "Ikona okna przeglądarki ze stroną internetową",
      description: `Funkcjonalne <strong>sklepy internetowe</strong> tworzone od podstaw
      lub na bazie wybranego szablonu graficznego.`,
    },
    {
      name: "Portale",
      to: "/portale-internetowe",
      img: ServiceIconDesktop,
      // src: <ServiceIconDesktopInline title="Desktop icon" />,
      alt: "Ikona monitora z mechanizmem",
      description: `Nowoczesne i&nbsp;intuicyjne <strong>portale www</strong>, projektowane 
      zgodnie z&nbsp;zasadami prawidłowego <strong>UX</strong> oraz <strong>UI.`,
    },
    {
      name: "Aplikacje",
      to: "/aplikacje",
      img: ServiceIconSoftware,
      // src: <ServiceIconSoftwareInline title="Software icon" />,
      alt: "Ikona zębatki ze znakiem kodu </>",
      description: `<strong>Dedykowane rozwiązania i oprogramowanie</strong> dopasowane do indywidualnych oczekiwań klienta.`,
    },
    {
      name: "Aplikacje mobilne",
      to: "/aplikacje-mobilne",
      img: ServiceIconMobile,
      // src: <ServiceIconMobileInline title="Mobile icon" />,
      alt: "Ikona smartfona </>",
      description: `Aplikacjie na <strong>urządzenia mobilne</strong>, tworzone na platformy
      <strong>Android</strong>, <strong>iOS</strong> oraz <strong>Windows
      Phone</strong>.`,
    },
    {
      name: "Grafika",
      to: "/projektowanie-graficzne",
      img: ServiceIconDesign,
      // src: <ServiceIconDesignInline title="Design icon" />,
      alt: "Ikona przedstawiająca logo w trakcie projektowania graficznego",
      description: `Kreacja, <strong>identyfikacja wizualna</strong>, grafika 3D, projekty 
      opakowań, animacja.`,
    },
    {
      name: "Doradztwo techniczne",
      to: "/konsulting",
      img: ServiceIconConsulting,
      // src: <ServiceIconConsultingInline title="Consulting icon" />,
      alt: "Ikona kierunkowskazu",
      description: `<strong>Kampanie marketingowe</strong>, audyty oprogramowania, 
      konsultacje techniczne, <strong>dokumentacja projektowa</strong>.`,
    },
    {
      name: "Administracja",
      to: "/administracja",
      img: ServiceIconInfrastructure,
      // src: <ServiceIconInfrastructureInline title="Infrastructure icon" />,
      alt: "Ikona przedstawiająca grupę serwerów połączonych ze sobą",
      description: `Administracja, hosting, opieka nad gotowym produktem...`,
    },
  ];

  render() {
    return (
      <>
        <div className={this.params.className + " offer-shortcut-wrapper"}>
          <ul className="r :c-3 :g :w :c-3:md :c-6:sm :c-6:xs :c-6:xxs :a-se ">
            {this.serviceIcon.map((item, index) => {
              return (
                <li key={index}>
                  <ALink
                    key={index}
                    title={item.name}
                    className="image"
                    to={item.to}
                    language={this.language}
                  >
                    <div>
                      <img src={item.img} alt={item.alt} key={index} />
                      {/* {item.src} */}
                      <h4>{item.name}</h4>
                    </div>
                  </ALink>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
}

export default OfferShortcut;
